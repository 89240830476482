<!-- 
/****************************************************************************/
/****************************************************************************/
  ResetPasswordView
  This is used in the normal flow for the first time a user logs in. It will
  also eventually be used when a user requests a password reset and click on
  a link in the email sent from ResetPasswordRequestView and actioned in
  resetPasswordEmailRoute.js

  A successful reset of the password (in resetPasswordRoute.js) updates 
  tbl_auth to:
  isActive: 1
  activatedDate 
  lastLoginDate
/****************************************************************************/
/****************************************************************************/
-->

<template>
   <v-card v-if="this.getToken() !== ''" width="400" class="mx-auto mt-5">
   <h1 class="display-1">
      <v-card-title>
         Reset Password
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            autofocus
            label="Username"
            v-model="username"
          />
          <v-text-field
            label="Password"
            type="password"
            v-model="password"
          />
          <v-text-field
            label="Confirm Password"
            type="password"
            v-model="password1"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="resetPassword" color="success">Reset Password</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-snackbar v-model="snackbar" :timeout="timeout">Passwrod Reset Failed, Please Try Again</v-snackbar>
   </h1>
   </v-card>
 </template>
 
 <script>
 import axios from 'axios'
 import { node_server } from '../../Helpers'
 import { mapActions, mapState } from 'pinia'
 import { useProfileStore } from '../store/ProfileStore' 
 
 export default {
   name: 'ResetPasswordView',

   data() {
     return {
       password: '',
       password1: '',
       snackbar: false,
       timeout: 2500,
       token: '',
       username: ''
     }
   },

    methods: {
      ...mapActions(useProfileStore, ['getUsername']),
      ...mapState(useProfileStore, ['getToken']),

      resetPassword(e) {
        e.preventDefault()

        axios.post(node_server + '/resetpassword',
          {
            password: this.password,
            password1: this.password1,
            token: this.getToken(),
            username: this.username
          }
        )
        .then( res => {
          console.log('ResetPasswordVuew created axios GET res.data is', res.data)
          if (res.data.goodResetPassword) {
            this.$router.push('/login')
          }
        } )
        .catch( error => {
          console.log('ResetPasswordVuew created axios GET error is', error)
          this.snackbar = true
        } )
      },
    },
  }

 </script>
 