<template>
   <img
      class="landing-pic-one"
      alt="Front of USAF Test Pilot School"
      :src="patch"
   />
   <div class="landing-pic-one-legend">
      <div class="text-no-wrap">
         Courtesy of Artist Douglas Castleman @ <a href="https://douglas-castleman.pixels.com/" target="_blank" rel="noreferrer noopener">Douglas Castleman</a>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'LandingLeftTop',

      data() {
         return {
            patch: './Patch, TPS, Grad, Std v2017 - tinyjpg.jpeg'
            }
         },
   }
</script>

<style scoped>
   .landing-pic-one {
      width: 200%;
      margin: 0rem 0 0.25rem 0;
   }
   .landing-pic-one-legend {
      align-self: center;
   }
</style>