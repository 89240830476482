<template>
  <v-card width="400" class="mx-auto mt-5">
    <h1 class="display-1">
      <v-card-title>
          Add New Class to tbl_class_page
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            autofocus
            label="TPS Class"
            v-model="tpsClass"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="addNewClass" color="success">Add New Class</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions> 
    </h1>
  </v-card>

  <v-card width="800" class="mx-auto mt-5">
    <h1 class="display-1">
      <v-card-title>
          Add New Alum to tbl_auth, tbl_grad_number and tbl_user_type
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            label="userId"
            v-model="userId"
          />
          <v-text-field
            label="Grad Number"
            v-model="gradNumber"
          />
          <v-text-field
            label="Username"
            v-model="username"
          />
          <v-text-field
            label="Password Hash"
            v-model="hash"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="updateTblAuthTblUserType" color="success">Update</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions> 
      <v-snackbar v-model="snackbar" :timeout="timeout">All 3 Tables Were Updated</v-snackbar>
    </h1>
  </v-card>
</template>
 
<script>
  import axios from 'axios'
  import { node_server, client_public_token } from '../../Helpers'
  import { mapState } from 'pinia'
  import { useProfileStore } from '../store/ProfileStore'

 
  export default {
    name: 'AddNewTPSClassView',
    data() {
    return {
      gradNumber: '',
      hash: '',
      snackbar: false,
      timeout: 2500,
      tpsClass: '',
      userId: '',
      username: ''
      }
    },

    methods: {
      ...mapState(useProfileStore, ['getToken', 'getUserId']),

      addNewClass(e) {
        e.preventDefault()
console.log('AddNewTPSClass tpsClass:', this.tpsClass.toLowerCase())
console.log('AddNewTPSClass token:', this.getToken())
        axios.post(node_server + '/addnewtpsclass/tblclasspage',
          {
              token: this.getToken(),
              tpsClass: this.tpsClass.toLowerCase(),
              userId: this.getUserId()
          })
          .then( res => {
            res.data
          } )
          .catch( error => {
          console.log('AddNewTPSClass created axios POST error is', error)
        } )
      },

      updateTblAuthTblUserType(e) {
        e.preventDefault()
console.log('AddNewTPSClass tpsClass:', this.tpsClass.toLowerCase())
console.log('AddNewTPSClass token:', this.getToken())
        axios.post(node_server + '/addnewtpsclass/addnewgrad',
          {
              gradNumber: this.gradNumber,
              hash: this.hash,
              token: this.getToken(),
              userId: this.getUserId(),
              newAlumUserId: this.userId,
              username: this.username
          })
          .then( res => {
            res.data

            if (res.data.tblAuthAffectedRows && res.data.tblGradNumberAffectedRows && res.data.tblUserTypeAffectedRows) {
              this.snackbar = true
            }

            this.gradNumber = ''
            this.hash = ''
            this.tpsClass = ''
            this.userId = ''
            this.username = ''
          } )
          .catch( error => {
          console.log('AddNewTPSClass created axios POST error is', error)
        } )
      },
    },
  }
</script>
 