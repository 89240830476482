<template>
  <v-form>
    <v-container>

      <v-row>
        <v-col cols="3" xs="12">
          <v-text-field
            autofocus
            label="Callsign"
            v-model="callsign"
          />
        </v-col>
        <v-col cols="3" xs="12">
          <v-text-field
            label="TPS Class (ex. 2002a)"
            v-model="tpsClass"
          />
        </v-col>
        <v-col cols="3" xs="12">
          <v-text-field
            label="Grad Number"
            v-model="gradNumber"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            label="Given First Name (required)"
            v-model="givenFirstName"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Preferred First Name"
            v-model="preferredFirstName"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Last Name (required)"
            v-model="lastName"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Suffix"
            v-model="suffix"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Address"
            v-model="address"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Apt"
            v-model="address2"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="City"
            v-model="city"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="State (ex. NC)"
            v-model="state"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Zip"
            v-model="zip"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-btn @click="addGrad" color="success">Add Grad</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'AddGradView',

  data() {
    return {
      address: "",
      address2: "",
      callsign: "",
      city: "",
      givenFirstName: "",
      gradNumber: "",
      lastName: "",
      preferredFirstName: "",
      state: "",
      suffix: "",
      token: "",
      tpsClass: "",
      zip: ""
    }
  },
  methods: {

    addGrad(e) {
      e.preventDefault()

    },

  }
}
</script>
