<template>
  <div class="text-h1">
    This is the Search Page for Logged in Users
  </div>
</template>

<script>
export default {
  name: 'SearchView',

  data() {
    return {
      password: 'zQf4A9janRPLbYj',
      token: "",
      username: 'george.burdell.1944A'
    }
  },
  methods: {

  }
}
</script>
