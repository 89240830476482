import { defineStore } from "pinia";

export const useProfileStore = defineStore('ProfileStore', {
   state() {
      return {
         profile: {
            callsign:            "",
            crudAnnouncements:   0,
            givenFirstName:      null,
            hasBio:              0,
            isActive:            0,
            lastName:            null,
            preferredFirstName:  null,
            token:               "",
            tpsClass:            null,
            userId:              "",
            username:            "",
            userType:            ""
         }
      }
   },

   actions: {
      clearProfile() {
         this.profile.crudAnnouncements   = 0
         this.profile.hasBio              = 0
         this.profile.isActive            = 0
         this.profile.userId              = ""
         this.profile.token               = ""
         this.profile.tpsClass            = ""
         this.profile.username            = ""
         this.profile.userType            = ""
      },
      updateProfile(obj) {
         obj.crudAnnouncements   ? this.profile.crudAnnouncements    = obj.crudAnnouncements    : null
         obj.givenFirstName      ? this.profile.givenFirstName       = obj.givenFirstName       : null
         obj.hasBio              ? this.profile.hasBio               = obj.hasBio               : null
         obj.isActive            ? this.profile.isActive             = obj.isActive             : null
         obj.lastName            ? this.profile.lastName             = obj.lastName             : null
         obj.token               ? this.profile.token                = obj.token                : null
         obj.tpsClass            ? this.profile.tpsClass             = obj.tpsClass             : null
         obj.userId              ? this.profile.userId               = obj.userId               : null
         obj.username            ? this.profile.username             = obj.username             : null
         obj.userType            ? this.profile.userType             = obj.userType             : null
      }
   },

   getters: {
      getProfile() {
         return this.profile
      },
      getToken() {
         return this.profile.token
      },
      getUserId() {
         return this.profile.userId
      },
      getUsername() {
         return this.profile.username
      }
   }
})