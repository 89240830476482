import { createRouter, createWebHistory } from 'vue-router'
import AddNewTPSClassView           from '../views/AddNewTPSClassView'
import AddGradView                     from '../views/AddGradView.vue'
import LandingView                  from '../views/LandingView.vue'
import SearchView                   from '../views/SearchView'
import LoginView                    from '../views/LoginView.vue'
import ResetPasswordRequestView     from '../views/ResetPasswordRequestView.vue'
import ResetPasswordView            from '../views/ResetPasswordView.vue'

const routes = [
  {
    path: '/addnewtpsclass',
    name: 'addnewtpsclass',
    component: AddNewTPSClassView
  },
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView
  },
  {
    path: '/addgrad',
    name: 'addgrad',
    component: AddGradView
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetPasswordView
  },
  {
    path: '/resetpasswordrequest',
    name: 'resetpasswordrequest',
    component: ResetPasswordRequestView
  },
  {
    path: '/yearbook',
    name: 'yearbook',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Yearbook.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
