<template>
  <v-card width="400" class="mx-auto mt-5">
    <h1 class="display-1">
      <v-card-title>
          Request a Password Reset Email
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            autofocus
            label="Email"
            v-model="email"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="sendEmail" color="success">Reset Password</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions> 
    </h1>
  </v-card>
</template>
 
<script>
  import axios from 'axios'
  import { node_server, client_public_token } from '../../Helpers'
  import { mapState } from 'pinia'
  import { useProfileStore } from '../store/ProfileStore'

 
  export default {
    name: 'ResetPasswordRequestView',
    data() {
    return {
      email: '',
      }
    },

    methods: {
      ...mapState(useProfileStore, ['getToken', 'getUsername']),

      sendEmail(e) {
        e.preventDefault()
console.log('ResetPasswordRequestView email:', this.email)
console.log('ResetPasswordRequestView token:', this.getToken())
console.log('ResetPasswordRequestView username:', this.getUsername())
        axios.post(node_server + '/resetpasswordemail', {email: this.email, token: this.getToken(), username: this.getUsername()})
          .then( res => {
            res.data
          } )
          .catch( error => {
          console.log('ResetPasswordRequestView created axios POST error is', error)
        } )
      },
    },
  }
</script>
 