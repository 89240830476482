<template>
   <v-expansion-panels>
      <v-expansion-panel v-for="(a, i) in announcements" :key="i" title="About TPS" text="What TPS is about...">
      </v-expansion-panel>
   </v-expansion-panels>
</template>

<script>
   import axios from 'axios'
   import { node_server, client_public_token } from '../../Helpers'

   export default {
      name: 'LandingLeftTop',

      data() {
         return {
            announcements: []
            }
         },

      mounted() {
         axios.defaults.headers.common['Authorization'] = `Bearer ${client_public_token}`
         axios.get(node_server + '/announcements/', {client_public_token: client_public_token})
            .then( res => {
console.log('Announcements page res.data.Announcements:', res.data.Announcements)
            this.announcements = res.data.Announcements
         } )
      },
   }
</script>
