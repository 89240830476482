<template>
   <LandingLeftTop />
   <LandingLeftBottom />
</template>

<script>
   import LandingLeftTop from './LandingLeftTop.vue'
   import LandingLeftBottom from './LandingLeftBottom.vue'

   export default {
      name: 'LandingLeft',

      components: {
         LandingLeftTop,
         LandingLeftBottom
      }
   }
</script>

<style scoped>
   .landing-pic-one {
      width: 200%;
      margin: 0rem 0 0.25rem 0;
   }
   .landing-pic-one-legeng {
      align-self: center;
   }
</style>