<!-- 
/****************************************************************************/
/****************************************************************************/
  LoginView
  There are possibilities:
    First ever login isActive: 0, no data is written to the store

    A successful reset of the password (in resetPasswordRoute.js) updates 
      tbl_auth to:
      isActive: 1
      activatedDate 
      lastLoginDate

    Login when isActive: 1 the login route checks tbl_user, if givenFirstName
      and lastName are populated then the user has entered data on the AddGrad
      page and can be routed to the search page, otherwise route to the 
      AddGrad page
/****************************************************************************/
/****************************************************************************/
-->
  
<template>
  <v-card width="400" class="mx-auto mt-5">
    <h1 class="display-1">
      <v-card-title>
          Alumni Login
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            autofocus
            label="Username"
            v-model="username"
          />
          <v-text-field
            label="Password"
            type="password"
            v-model="password"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="resetPassword" color="success">Reset Password</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="login" color="info">Login</v-btn>
      </v-card-actions> 
      <v-snackbar v-model="snackbar" :timeout="timeout">Login Failed, Please Try Again</v-snackbar>
    </h1>
  </v-card>
</template>
 
<script>
  import axios from 'axios'
  import { node_server, client_public_token } from '../../Helpers'
  import { mapActions } from 'pinia'
  import { useProfileStore } from '../store/ProfileStore'
 
  export default {
    name: 'LoginView',
    data() {
    return {
      password: '',
      snackbar: false,
      timeout: 2500,
      tpsClass: '',
      username: ''
      }
    },

    methods: {
      ...mapActions(useProfileStore, ['updateProfile']),

      login(e) {
        e.preventDefault()

        if (this.password.length >= 8 && this.username.length >= 8) {
          // The setTimeout is to limit submissions to 4 per second
          setTimeout( () => {
            axios.post(node_server + '/profile/login',
            {
              password: this.password,
              client_public_token: client_public_token,
              token: this.token,
              username: this.username
            }
          )
          .then( res => {
            console.log('LoginView created axios GET res.data is', res.data)

            if (res.data.givenFirstName !== null && res.data.lastName !== null) {
              this.updateProfile({
                givenFirstName: res.data.givenFirstName,
                isActive: res.data.isActive,
                lastName: res.data.lastName,
                token: res.data.token,
                tpsClass: res.data.tpsClass,
                userId: res.data.userId,
                username: res.data.username.toLowerCase(),
                userType: res.data.userType
            })
            }
            else {
              this.updateProfile({
                token: res.data.token,
                username: res.data.username
            })
            }

            if (!res.data.isActive) {
              this.$router.push('/resetpassword')
            }
            else if (res.data.isActive && res.data.givenFirstName !== null && res.data.lastName !== null) {
              this.$router.push('/search')
            }
            else {
              this.$router.push('/addgrad')
            }
            
          } )
          .catch( error => {
            this.password = ""
            this.username = ""
            this.snackbar = true
            const numBadTimes = error.response.data.numBadTimes
            if (numBadTimes >= 3 && numBadTimes !== 5) {
              console.log('You have', 5 - numBadTimes, 'more login attempts before your account will be locked for one hour')
            }
            else if (numBadTimes === 5) {
              console.log('Your account has been locked for one hour')
            }
          } )
          }, 250 )
        }
        else {
          // Generate a popup about inproper inputs
          console.log('Improper Inputs')
        }
      },

      resetPassword() {
        this.$router.push('/resetpasswordrequest')
      }
    },
  }
</script>
 