<template>
   <div>
      <div class="text-h4 notableAlumniHeader">
         Notable Alumni
      </div>
      <div class="notableAlumni">
         <div v-for="(alum, i) in notableAlumni" :key="i">
            <div class="text-h6 font-weight-bold text-left text-no-wrap notable-alumni-line1">
               {{ alum.militaryRank }} {{ alum.givenFirstName }} {{ alum.lastName }} (Alumni #{{ alum.gradNumber }})
            </div>
            <div class="text-body text-right">
               {{ alum.aceUserId ? "Ace" : null }}
            </div>
            <div class="text-body text-right text-no-wrap">
               {{ alum.notableAlumniAchievement1 }}
            </div>
            <div class="text-body text-right text-no-wrap">
               {{ alum.notableAlumniAchievement2 }}
            </div>
            <div class="text-body text-right text-no-wrap">
               {{ alum.notableAlumniAchievement3 }}
            </div>
            <div class="text-body text-right text-no-wrap">
               {{ alum.notableAlumniAchievement4 }}
            </div>
            <div class="text-body text-right text-no-wrap">
               {{ alum.notableAlumniAchievement5 }}
            </div>
            <div class="text-body text-right text-no-wrap" v-if="alum.nahfInducteeYear !== null">
               <a :href=alum.nahf_url target="_blank" rel="noreferrer noopener" class="nahf-link">{{ alum.nahfInducteeYear }} - {{ alum.urlText }}</a>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import axios from 'axios'
   import { node_server, client_public_token } from '../../Helpers'

   export default {
      name: 'LandingRight',

      data() {
         return {
            notableAlumni: []
            }
         },

      mounted() {
         axios.defaults.headers.common['Authorization'] = `Bearer ${client_public_token}`
         axios.get(node_server + '/notablealumni/', {client_public_token: client_public_token})
            .then( res => {
            this.notableAlumni = res.data['Notable Alumni']
         } )
      },
   }
</script>

<style scoped>
   .notableAlumni {
      height: 800px;
      overflow: scroll
   }
   .notableAlumniHeader {
      position: sticky;
      top: 20px;
   }
   .nahf-link {
      color: inherit;
   }
   .notable-alumni-line1 {
      margin-top: 30px;
   }
</style>