<template>
   <v-container class="landing-page-container">
      <v-row align-content="center">
         <v-col cols="4" xs="12">
            <LandingLeft />
         </v-col>
         <v-col cols="4" xs="12">
            <LandingMiddle />
         </v-col>
         <v-col cols="4" xs="12">
            <LandingRight />
         </v-col>
      </v-row>
   </v-container>
   
</template>

<script>
   import LandingLeft from '../components/LandingLeft.vue'
   import LandingMiddle from '../components/LandingMiddle.vue'
   import LandingRight from '../components/LandingRight.vue'
 
   export default {
      name: 'LoginView',

      components: {
         LandingLeft,
         LandingMiddle,
         LandingRight
      },
   }
</script>

<style scoped>
   .landing-page-container {
      margin: 50px;
   }
</style>