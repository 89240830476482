
<template>
   <div>
     <v-toolbar
       dark
       prominent
       image="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
     >
       <v-app-bar-nav-icon></v-app-bar-nav-icon>
 
       <v-toolbar-title>USAF TPS Alumni</v-toolbar-title>
 
       <v-spacer></v-spacer>

       <button @click="login"
        v-if="
          currentRouteName !== 'login' &&
          currentRouteName !== 'home'"
          >
        <div class="login-button">
          Alumni Login
        </div>
      </button>

      <button @click="logout" v-else>
        <div class="login-button">
          Logout
        </div>
      </button>
 
     </v-toolbar>
   </div>
 </template>

<script>
  import { mapActions } from 'pinia'
  import { useProfileStore } from '../store/ProfileStore'

  export default {
    name: 'Navbar',

    data() {
     return {
      token: ""
     }
   },

    methods: {
      ...mapActions(useProfileStore, ['clearProfile', 'updateProfile']),

      login() {
        this.$router.push('/login')
      },

      logout() {
        this.clearProfile()
        this.$router.push('/')
      }
    },

    computed: {
      currentRouteName() {
        return this.$route.name
      },
    },
  }
</script>
 
<style scoped>
  .login-button {
    color: white;
    margin-right: 25px;
  }
</style>