<template>
  <Navbar />
  <nav>
    <!-- <router-link to="/">Login</router-link> |
    <router-link to="/home">Home</router-link> |
    <router-link to="/resetpassword">Reset Password</router-link> |
    <router-link to="/yearbook">Yearbook</router-link> -->
  </nav>
  <router-view/>
</template>

<script>
import Navbar from './components/Navbar.vue'
import { useProfileStore } from './store/ProfileStore'

export default {
  components: {
    Navbar
  },

  setup() {
      const profileStore = useProfileStore()

      return {
        profileStore
      }
    },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
